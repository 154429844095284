import React from 'react'

import ProductCell from '../ProductCell'
import './productColumn.css'
import { titleClass } from '../../utils/titleClass'

const ProductColumn = ({ className, title, titleColor, light, children, url }) => (
  <div
    className={`${
      light ? 'productColumn--light ' : ''
    }fl w-100 w-50-m w-25-l tc pa3 pa2-l ${className}`}
  >
    <a className="no-underline" href={url}>
      <div
        className={`h3 pv1 fw9 productColumn__title ${titleClass[titleColor] ||
          titleClass['default']}`}
      >
        <ProductCell>{title}</ProductCell>
      </div>
    </a>
    {children}
  </div>
)

export default ProductColumn
