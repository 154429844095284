import React from 'react'

const ProductCell = ({ description, right, higher, children }) => (
  <div
    className={`${right ? 'tr pr5' : description ? 'fw9' : 'tc'} ${
      higher ? 'h3' : ''
    } f6 pv1`}
  >
    {children}
  </div>
)

export default ProductCell
