import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import sortBy from 'lodash/fp/sortBy'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import replace from 'lodash/fp/replace'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import Layout from '../layouts/layout'
import ProductCell from '../components/ProductCell'
import ProductColumn from '../components/ProductColumn'

const ValuesList = ({ values }) => (
  <>
    {values.sort().map((_, i) => (
      <span key={_}>{`${_}${i < values.length - 1 ? ', ' : ''}`}</span>
    ))}
  </>
)
const Dot = ({ color }) => (
  <div className="br-100 w1 h1" style={{ backgroundColor: color }}>
    &nbsp;
  </div>
)
const Mesure = ({ value }) => (
  <div className="flex justify-around ph4 h1">
    <Dot color={`${value > 0 ? '#003876' : '#dcdcdc'}`} />
    <Dot color={`${value > 1 ? '#003876' : '#dcdcdc'}`} />
    <Dot color={`${value > 2 ? '#003876' : '#dcdcdc'}`} />
  </div>
)
const ProductData = ({ data }) => (
  <a
    href={`/${data.key}`}
    style={{ textDecorationLine: 'none', color: 'black' }}
  >
    <div
      className="pa4 lh-copy"
      style={{ minHeight: '10rem', paddingBottom: 0 }}
    >
      {data.shortDescription}
    </div>
    <img alt={data.name} src={replace('http:', 'https:', data.image.url)} />
    <ProductCell>
      <div className="f6 grow no-underline br-pill ph3 pv2 mb2 dib white bg-dark-blue">
        Koupit
      </div>
    </ProductCell>
  </a>
)

const IndexPage = () => {
  const [mainPage, setMainPage] = useState({})

  useEffect(() => {
    fetch('/api/main-page')
      .then(resp => resp.json())
      .then(mainPage => {
        setMainPage(mainPage)
      })
  }, [])

  return (
    <Layout>
      {() => (
        <StaticQuery
          query={graphql`
            query productsView {
              allProduct {
                edges {
                  node {
                    id
                    key
                    titleColor
                    name
                    finish
                    grammages
                    formats
                    presentations
                    textGraphics
                    photoBooks
                    finishingPostProcessing
                    image {
                      url
                    }
                    defaultSort
                    shortDescription
                  }
                }
              }
            }
          `}
          render={data => {
            const products = sortBy(['node.defaultSort'], data.allProduct.edges)
            return (
              <>
                {mainPage.carousel && mainPage.carousel.length > 0 && (
                  <Carousel
                    className="ph2"
                    autoPlay
                    infiniteLoop
                    showThumbs={false}
                  >
                    {map(
                      image => (
                        <div key={get('file.filename', image)}>
                          <img src={`/media/${get('file.filename', image)}`} />
                        </div>
                      ),
                      get('carousel', mainPage)
                    )}
                  </Carousel>
                )}
                <div className="db fl w-100 pv4">
                  <ProductColumn
                    className="db"
                    title="Color Copy Original"
                    titleColor="blue"
                    light
                    url={`/${products[0].node.key}`}
                  >
                    <ProductData data={products[0].node} />
                  </ProductColumn>
                  <ProductColumn
                    className="db"
                    title="Color Copy Style"
                    titleColor="gold"
                    url={`/${products[1].node.key}`}
                  >
                    <ProductData data={products[1].node} />
                  </ProductColumn>
                  <ProductColumn
                    className="db"
                    title="Color Copy Coated Silk"
                    titleColor="silverLight"
                    light
                    url={`/${products[2].node.key}`}
                  >
                    <ProductData data={products[2].node} />
                  </ProductColumn>
                  <ProductColumn
                    className="db"
                    title="Color Copy Coated Glossy"
                    titleColor="silver"
                    url={`/${products[3].node.key}`}
                  >
                    <ProductData data={products[3].node} />
                  </ProductColumn>
                </div>
                <div
                  className="db fl w-100"
                  dangerouslySetInnerHTML={{ __html: mainPage.text }}
                />
              </>
            )
          }}
        />
      )}
    </Layout>
  )
}

export default IndexPage
